<template>
  <div>
    <div v-if="isMobile">
      <MobileHome></MobileHome>
    </div>
    <div v-else>
      <WebHome></WebHome>
    </div>
  </div>
</template>

<script>
import MobileHome from "@/mobile/MobileHome.vue";
import WebHome from "@/web/WebHome.vue";

export default {
  name: "FormCenter",
  props: {},
  components: {
    MobileHome,
    WebHome
  },
  data() {
    return {
      isMobile: false
    };
  },
  created() {
    this.checkDevice();
  },
  methods: {
    checkDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则表达式检测移动设备
      if (
        /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
          userAgent
        )
      ) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
</style>

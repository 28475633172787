<template>
  <div class="skinStoreWrapper">
    <div class="section" v-for="(section,index) in sections" :key="index">
      <div class="sectionHeader">
        <h2>{{ section.title }}</h2>
        <a :href="getMmsSkinMallUrl()">
          更多
          <img src="../assets/mobileMenu_arrow.png" />
        </a>
      </div>
      <div class="skinList">
        <div
          class="slider"
          :style="{ transform: `translateX(-${section.currentIndex * (162 + 16)}px)` }"
        >
          <div
            class="skinItem"
            v-for="(skin, skinIndex) in section.skins"
            :key="skinIndex"
            @click="jumpMmsSkinMall()"
          >
            <img :src="skin.imageUrl" />
          </div>
          <div class="skinItem more" @click="jumpMmsSkinMall()">
            查看更多
            <img src="../assets/mobileMenu_arrow.png" />
          </div>
        </div>
      </div>

      <!--轮播图导航按钮-->
      <div class="swiperPrev" @click="prevSlide(index)" v-show="section.currentIndex > 0">
        <img src="../assets/arrow_left_dark.png" />
      </div>
      <div
        class="swiperNext"
        @click="nextSlide(index)"
        v-show="section.currentIndex < section.skins.length - 1"
      >
        <img src="../assets/arrow_right_dark.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { getMmsUrl } from "@/utils/util";

export default {
  name: "MobileSkinStore",
  data() {
    return {
      sections: [
        {
          title: "大转盘",
          skins: [
            {
              imageUrl: require("../assets/templates/dazhuanpan1-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/dazhuanpan2-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/dazhuanpan3-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/dazhuanpan4-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/dazhuanpan5-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/dazhuanpan6-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/dazhuanpan7-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/dazhuanpan8-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/dazhuanpan9-main.jpg")
            }
          ],
          currentIndex: 0
        },
        {
          title: "九宫格",
          skins: [
            {
              imageUrl: require("../assets/templates/jiugongge1-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/jiugongge2-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/jiugongge3-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/jiugongge4-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/jiugongge5-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/jiugongge6-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/jiugongge7-main.jpg")
            }
          ],
          currentIndex: 0
        },
        {
          title: "刮刮乐",
          skins: [
            {
              imageUrl: require("../assets/templates/guaguale1-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/guaguale2-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/guaguale3-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/guaguale4-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/guaguale5-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/guaguale6-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/guaguale7-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/guaguale8-main.jpg")
            }
          ],
          currentIndex: 0
        },
        {
          title: "盲盒",
          skins: [
            {
              imageUrl: require("../assets/templates/manghechoujiang1-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/manghechoujiang2-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/manghechoujiang3-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/manghechoujiang4-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/manghechoujiang5-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/manghechoujiang6-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/manghechoujiang7-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/manghechoujiang8-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/manghechoujiang9-main.jpg")
            }
          ],
          currentIndex: 0
        },
        {
          title: "水果机",
          skins: [
            {
              imageUrl: require("../assets/templates/shuiguoji1-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/shuiguoji2-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/shuiguoji3-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/shuiguoji4-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/shuiguoji5-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/shuiguoji6-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/shuiguoji7-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/shuiguoji8-main.jpg")
            }
          ],
          currentIndex: 0
        },
        {
          title: "砸金蛋",
          skins: [
            {
              imageUrl: require("../assets/templates/zajindan1-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/zajindan2-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/zajindan3-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/zajindan4-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/zajindan5-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/zajindan6-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/zajindan7-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/zajindan8-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/zajindan9-main.jpg")
            },
            {
              imageUrl: require("../assets/templates/zajindan10-main.jpg")
            }
          ],
          currentIndex: 0
        }
      ]
    };
  },
  methods: {
    getMmsSkinMallUrl() {
      return getMmsUrl("/skin/mall");
    },
    jumpMmsSkinMall() {
      window.location.href = this.getMmsSkinMallUrl();
    },
    prevSlide(sectionIndex) {
      if (this.sections[sectionIndex].currentIndex > 0) {
        this.sections[sectionIndex].currentIndex -= 1;
      }
    },
    nextSlide(sectionIndex) {
      if (
        this.sections[sectionIndex].currentIndex <
        this.sections[sectionIndex].skins.length - 1
      ) {
        this.sections[sectionIndex].currentIndex += 1;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.skinStoreWrapper {
  padding: 0 16px 40px;
  .section {
    position: relative;
    margin-top: 40px;
    .sectionHeader {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 18px;
      }
      a {
        display: flex;
        align-items: center;
        color: #7f8792;
        white-space: nowrap;
        text-decoration: none;
        img {
          margin-left: 4px;
          width: 10px;
          opacity: 0.7;
        }
      }
    }
    .skinList {
      overflow: hidden;
      .slider {
        display: flex;
        flex-wrap: nowrap;
        .skinItem {
          width: 162px;
          flex-shrink: 0;
          height: 259px;
          overflow: hidden;
          background: #f6f7f9;
          border-radius: 12px;
          margin-right: 16px;
          img {
            width: 100%;
          }
          &.more {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #9da3ac;
            font-size: 14px;
            margin-right: 0;
            img {
              width: 8px;
              margin-left: 4px;
              opacity: 0.7;
            }
          }
        }
      }
      //   &::before,
      //   &::after {
      //     position: absolute;
      //     top: 0;
      //     z-index: 1;
      //     display: block;
      //     width: 32px;
      //     height: 100%;
      //     content: "";
      //     background: rgba(255, 255, 255, 0.8);
      //   }
      //   &::before {
      //     left: -2px;
      //     mask: linear-gradient(to left, #f7fbfd00, #f7fbfd);
      //   }
      //   &::after {
      //     right: -2px;
      //     mask: linear-gradient(to right, #f7fbfd00, #f7fbfd);
      //   }
    }

    .swiperPrev,
    .swiperNext {
      position: absolute;
      z-index: 2;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
      background: #ffffff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 148px;
      img {
        width: 20px;
      }
    }
    .swiperPrev {
      left: -16px;
    }
    .swiperNext {
      right: -16px;
    }
  }
}
</style>
<template>
  <div>
    <div class="drawerMenusOpen" @click="toggleDrawer">
      <img src="../assets/menu_icon.png" />
    </div>
    <div class="drawerWrapper" v-if="isDrawerOpen">
      <div class="drawerHeader">
        <div class="drawerMenusClose" @click="toggleDrawer">
          <img src="../assets/menu_close_icon.png" />
        </div>
        <span class="title">菜单</span>
      </div>
      <div class="drawerBody">
        <div class="tabList" v-for="(tab, index) in tabs" :key="index">
          <div class="collapseHeader" @click="toggleCollapse(index)">
            <span>{{ tab.title }}</span>
            <img
              src="../assets/mobileMenu_arrow.png"
              :style="{ transform: tab.isOpen ? 'rotate(90deg)' : 'rotate(0deg)' }"
            />
          </div>
          <div class="collapseContent" v-if="tab.isOpen">
            <div class="links" v-for="(link, idx) in tab.links" :key="idx">{{ link }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isDrawerOpen: false,
      tabs: [
        {
          title: "模板素材",
          isOpen: false,
          links: ["二级菜单1", "二级菜单2", "二级菜单3"]
        },
        {
          title: "设计场景",
          isOpen: false,
          links: ["二级菜单1", "二级菜单2", "二级菜单3"]
        }
      ]
    };
  },
  methods: {
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    toggleCollapse(index) {
      this.tabs[index].isOpen = !this.tabs[index].isOpen;
    }
  }
};
</script>
<style lang="less" scoped>
.drawerMenusOpen {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  background: #f1f2f4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  .drawerHeader {
    height: 60px;
    padding: 0 20px;
    background: #ffffff;
    border-bottom: 1px solid #f1f2f4;
    display: flex;
    align-items: center;
    .drawerMenusClose {
      display: flex;
      align-items: center;
      margin-right: 24px;
      img {
        width: 20px;
      }
    }
    .title {
      font-size: 16px;
    }
  }
  .drawerBody {
    padding-top: 16px;
    padding-bottom: 16px;
    height: calc(100vh - 60px);
    overflow-y: auto;
    .tabList {
      margin: 0 20px;
      .collapseHeader {
        padding: 16px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 18px;
          color: #1a1a1a;
          font-weight: bold;
        }
        img {
          width: 14px;
        }
      }
      .collapseContent {
        padding: 20px 0 4px;
        .links {
          font-size: 16px;
          color: #4c535c;
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>

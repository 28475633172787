<template>
  <div>
    <div class="header">
      <div class="header_logo_wrapper">
        <img src="../assets/logo.png" />
        <div class="title">7600</div>
      </div>
      <div class="navigation">
        <!-- index argument -->
        <a
          v-for="(item, index) in menuItems"
          :key="index"
          @mouseover="showSubMenu(index)"
          @mouseleave="hideSubMenu()"
          :class="{ 'active': activeMenuItem === index }"
        >
          <span class="firstMenu">{{ item.title }}</span>
          <div class="activeLine">
            <div class="activeLineInner"></div>
          </div>
        </a>
      </div>

      <div
        class="subMenuDropdowm menuActive2"
        v-show="hoveredMenuIndex == 2"
        @mouseenter="subMenuEnter(2)"
        @mouseleave="subMenuLeave()"
      >
        <div class="subMenuList">
          <h2>营销干货</h2>
          <div class="grid subMenuItem">
            <span v-for="(item,index) in subMenuItems2" :key="index">{{item.title}}</span>
          </div>
        </div>
      </div>
      <div
        class="subMenuDropdowm menuActive1"
        v-show="hoveredMenuIndex == 1"
        @mouseenter="subMenuEnter(1)"
        @mouseleave="subMenuLeave()"
      >
        <div class="subMenuList">
          <h2>行业解决方案</h2>
          <div class="grid subMenuItem">
            <span
              v-for="(item,index) in subMenuItems"
              :key="index"
              :class="{subActive:activeIndex === index}"
              @click="selectSubMenuItem(index)"
            >{{item.name}}</span>
          </div>
        </div>
        <div class="grid subMenuContent">
          <div class="memberImg">
            <img :src="selectedContent.imgSrc" />
          </div>
          <div class="descMark">
            <span v-for="(desc, index) in selectedContent.descriptions" :key="index">{{desc}}</span>
          </div>
          <div class="toDetailBtn" @click="jumpMmsSkinMall()">了解更多详情</div>
        </div>
        <div class="grid companyKefu">
          <h2 class="kefuTitle">微信扫码添加专属业务经理</h2>
          <div class="companyQrcode">
            <img src="../assets/qrcodeImg.jpg" />
          </div>
          <div class="kefuDetail">
            <p>工作日8:30-18:00</p>
            <p>投诉邮箱：ssq@kapool.cn</p>
          </div>
        </div>
      </div>

      <div class="header_opts">
        <a :href="mmsurl" class="btn_login">登录/注册</a>
      </div>
    </div>
  </div>
</template>

<script>
import { getMmsUrl } from "@/utils/util";
export default {
  name: "HeaderRoot",
  props: {},
  data() {
    return {
      mmsurl: process.env.VUE_APP_MMS_BASE_URL,
      menuItems: [
        { title: "免费模板" },
        { title: "解决方案" },
        { title: "营销干货" },
        { title: "API对接" },
        { title: "关于我们" }
      ],
      activeMenuItem: 0,
      hoveredMenuIndex: -1,
      // subMenuVisible: [false, false], // 对应每个子菜单的可见性
      activeIndex: 0,
      subMenuItems: [
        { name: "零售行业解决方案" },
        { name: "酒水行业解决方案" },
        { name: "金融行业解决方案" },
        { name: "家居行业解决方案" },
        { name: "珠宝行业解决方案" },
        { name: "美妆行业解决方案" },
        { name: "虚拟行业解决方案" },
        { name: "快消行业解决方案" }
      ],
      menuContents: [
        {
          imgSrc: require("../assets/member_bg1.png"),
          descriptions: ["一物一码", "大转盘", "红包"]
        },
        {
          imgSrc: require("../assets/member_bg2.png"),
          descriptions: ["一物一码", "大转盘", "红包"]
        },
        {
          imgSrc: require("../assets/member_bg3.png"),
          descriptions: ["一物一码", "大转盘", "红包"]
        },
        {
          imgSrc: require("../assets/member_bg4.png"),
          descriptions: ["一物一码", "大转盘", "红包"]
        },
        {
          imgSrc: require("../assets/member_bg5.png"),
          descriptions: ["一物一码", "大转盘", "红包"]
        },
        {
          imgSrc: require("../assets/member_bg6.png"),
          descriptions: ["一物一码", "大转盘", "红包"]
        },
        {
          imgSrc: require("../assets/member_bg7.png"),
          descriptions: ["一物一码", "大转盘", "红包"]
        },
        {
          imgSrc: require("../assets/member_bg7.png"),
          descriptions: ["一物一码", "大转盘", "红包"]
        }
      ],
      subMenuItems2: [
        { title: "网上互动文章1" },
        { title: "网上互动文章2" },
        { title: "网上互动文章3" },
        { title: "网上互动文章4" },
        { title: "广告文章" },
        { title: "广告文章" },
        { title: "广告文章" },
        { title: "广告文章" }
      ]
    };
  },
  computed: {
    selectedContent() {
      return this.menuContents[this.activeIndex];
    }
  },
  mounted() {
    console.log("URL::" + process.env.VUE_APP_MMS_BASE_URL);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getMmsSkinMallUrl() {
      return getMmsUrl("/skin/mall");
    },
    jumpMmsSkinMall() {
      window.location.href = this.getMmsSkinMallUrl();
    },
    //菜单固定
    handleScroll() {
      this.isHeaderFixed = window.scrollY > 0;
    },
    //二级菜单浮层
    showSubMenu(index) {
      // console.log("showSubMenu");
      // console.log(index);
      this.hoveredMenuIndex = index;
      // for (let i = 0; i < this.subMenuVisible.length; i++) {
      //   if (i == index) {
      //     this.subMenuVisible[index] = true;
      //   } else {
      //     this.subMenuVisible[index] = false;
      //   }
      // }
    },
    hideSubMenu() {
      // index:  parameter
      // 如果鼠标是从子菜单离开的，我们不需要立即隐藏子菜单，因为可能只是移动到另一个子菜单或主菜单项上
      // 但如果鼠标是从主菜单项离开的，并且不是进入子菜单，我们应该隐藏子菜单
      this.hoveredMenuIndex = -1; // 重置悬停的菜单项索引（如果需要）
    },
    subMenuEnter(index) {
      this.hoveredMenuIndex = index;
    },
    subMenuLeave() {
      this.hoveredMenuIndex = -1;
      // this.subMenuVisible[index] = false;
    },
    selectSubMenuItem(index) {
      this.activeIndex = index;
    },
    gotomms() {
      window.location.href = "https://t.7600.cn/mms";
    }
  }
};
</script>


<style lang="less" scoped>
.header {
  width: 100%;
  min-width: 1200px;
  height: 60px;
  padding: 0 40px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: #ffffff;
  top: 0;

  .header_logo_wrapper {
    display: flex;
    align-items: center;
    height: 42px;
    cursor: pointer;
    color: #333333;
    img {
      height: 42px;
    }
    .title {
      width: 80px;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .navigation {
    flex-grow: 1;
    flex-shrink: 0;
    margin-left: 20px;
    position: relative;

    a {
      position: relative;
      display: inline-block;
      margin: 0 32px;
      width: 64px;
      line-height: 50px;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      .firstMenu {
        color: #333333;
        font-size: 14px;
      }
      .activeLine {
        width: 100%;
        position: absolute;
        bottom: 8px;
        left: 0;
        display: none;
        justify-content: center;
        .activeLineInner {
          width: 14px;
          height: 3px;
          border-radius: 6px;
          background: #3388ff;
        }
      }
      &.active {
        .firstMenu {
          color: #3388ff;
          font-weight: 600;
        }
        .activeLine {
          display: inline-flex;
        }
      }
      &:hover {
        .firstMenu {
          color: #3388ff;
          cursor: pointer;
        }
      }
    }
  }
  .subMenuDropdowm {
    position: absolute;
    top: 56px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 12px 48px 16px rgba(0, 0, 0, 0.03),
      0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 6px 16px -8px rgba(0, 0, 0, 0.08);
    display: flex;
    overflow: hidden;
    .subMenuList {
      margin: 20px 0 28px 20px;
      width: 160px;
      flex-shrink: 0;
      border-right: 1px solid #e6ebed;
      h2 {
        font-size: 16px;
        line-height: 16px;
        padding-bottom: 16px;
        color: #333333;
      }
      .subMenuItem {
        display: flex;
        flex-wrap: wrap;
        span {
          width: 100%;
          font-size: 14px;
          height: 34px;
          line-height: 34px;
          color: #333333;
          white-space: nowrap;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            cursor: pointer;
            color: #3388ff;
          }
          &.subActive {
            padding-left: 16px;
            color: #3388ff;
            font-weight: bold;
            &::before {
              position: absolute;
              content: "";
              width: 4px;
              height: 4px;
              background: #3388ff;
              border-radius: 2px;
              left: 0;
              top: 50%;
              top: 15px;
              opacity: 0.8;
            }
          }
        }
      }
    }
    .subMenuContent {
      flex-grow: 1;
      padding: 20px 20px 40px 20px;
      .memberImg {
        background: #e7efff;
        width: 100%;
        height: 180px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
        }
      }
      .descMark {
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
        span {
          background: #f3f4f7;
          color: #333333;
          border-radius: 2px;
          padding: 0 8px;
          line-height: 24px;
          font-size: 12px;
          margin-right: 6px;
        }
      }
      .toDetailBtn {
        width: 140px;
        margin-top: 40px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        text-align: center;
        border-radius: 6px;
        background: #3388ff;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .companyKefu {
      width: 232px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      background: linear-gradient(135deg, #f2f7ff, #fff);

      .kefuTitle {
        color: #333;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
      .companyQrcode {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 16px;
        img {
          width: 120px;
        }
      }
      .kefuDetail {
        p {
          font-size: 14px;
          color: #666666;
          line-height: 24px;
          text-align: center;
        }
      }
    }
    &.menuActive1 {
      width: 720px;
      left: 344px;
    }
    &.menuActive2 {
      left: 464px;
      .subMenuList {
        width: 480px;
        border-right: none;
        .subMenuItem {
          span {
            width: 50%;
            padding-right: 24px;
          }
        }
      }
    }
  }
  .header_opts {
    display: flex;
    align-items: center;
    .btn_login {
      height: 36px;
      border-radius: 4px;
      background: #38f;
      border: 0;
      padding: 8px 16px;
      font-size: 12px;
      color: #ffffff;
      cursor: pointer;
      white-space: nowrap;
      text-decoration: none;
    }
  }
}
</style>

<template>
  <div class="mobileFooter">
    <div class="corp">苏ICP备15058152号-6</div>
    <div class="corp">© 2023-2030 江苏卡池数字科技有限公司</div>
    <div class="corp">增值电信业务经营许可证：苏B2-20182724</div>
  </div>
</template>

<script>
export default {
  name: "FooterRoot",
  props: {},
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.mobileFooter {
  background: #f6f7f9;
  color: #7f8792;
  padding: 32px 12px;
  font-size: 16px;
  white-space: nowrap;
  .corp {
    line-height: 22px;
    text-align: center;
  }
}
</style>

<template>
  <div class="mobileHeader">
    <div class="navLogo">
      <img src="../assets/logo.png" />
    </div>

    <MobileMenus></MobileMenus>

    <div class="mallSearchInput">
      <input type="text" placeholder="搜索模板" v-model="searchInput" />
      <span class="clearIcon" v-if="searchInput" @click="clearInput">
        <img src="../assets/clearIcon.png" />
      </span>
      <span class="searchButton">搜索</span>
    </div>
  </div>
</template>

<script>
import MobileMenus from "./MobileMenus.vue";

export default {
  name: "MobileHeaderRoot",
  components: { MobileMenus },
  props: {},
  data() {
    return {
      searchInput: ""
    };
  },
  mounted() {},
  methods: {
    clearInput() {
      this.searchInput = "";
    }
  }
};
</script>

<style lang="less" scoped>
.mobileHeader {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 12px;
  background: #ffffff;
  border-bottom: 1px solid #f1f2f4;
  .navLogo {
    width: 60px;
    flex-shrink: 0;
    margin-right: 8px;
    img {
      width: 100%;
    }
  }
  .mallSearchInput {
    flex-grow: 1;
    height: 52px;
    background: #ffffff;
    border: 1px solid #e8eaec;
    border-radius: 10px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
      border-color: #3388ff;
    }
    input[type="text"] {
      width: 100%;
      height: 44px;
      flex: 1;
      border: 0;
      outline: none;
      font-size: 16px;
      padding-left: 8px;
    }
    .clearIcon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
    .searchButton {
      padding: 0 20px;
      line-height: 40px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      background-color: #3388ff;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
</style>

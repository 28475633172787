<template>
  <div class="footer">
    <div class="footer_container">
      <div class="list">
        <div class="product">
          <div class="item">
            <div class="title">免费模板</div>
            <!-- <ul>
              <li v-for="index in 3" :key="index">
                <a href="#" target="_blank">能源式分布</a>
              </li>
            </ul>-->
          </div>
          <div class="item">
            <div class="title">解决方案</div>
            <!-- <ul>
              <li v-for="index in 3" :key="index">
                <a href="#" target="_blank">实时计算平台</a>
              </li>
            </ul>-->
          </div>
          <div class="item">
            <div class="title">营销干货</div>
            <!-- <ul>
              <li v-for="index in 2" :key="index">
                <a href="#" target="_blank">制造大数据</a>
              </li>
            </ul>-->
          </div>
          <div class="item">
            <div class="title">API对接</div>
            <!-- <ul>
              <li v-for="index in 2" :key="index">
                <a href="#" target="_blank">7600生态计划</a>
              </li>
            </ul>-->
          </div>
          <div class="item">
            <div class="title">关于我们</div>
            <!-- <ul>
              <li v-for="index in 3" :key="index">
                <a href="#" target="_blank">低代码社区</a>
              </li>
            </ul>-->
          </div>
        </div>
        <div class="code">
          <div class="qr_box">
            <img src="../assets/qrcodeImg.jpg" />
            <p>扫码关注7600</p>
          </div>
          <div class="serve_info">
            <p>咨询热线:</p>
            <p class="phone">400-025-0086</p>
            <p class="tip">工作时间：8:30~22:00</p>
          </div>
        </div>
      </div>
      <div class="corp">
        <div class="corp_list">
          <span>Copyright © 2023-2030 江苏卡池数字科技有限公司</span>
          <span class="divider">|</span>
          <span>苏ICP备15058152号-6</span>
          <span class="divider">|</span>
          <span>增值电信业务经营许可证：苏B2-20182724</span>
        </div>
        <div class="corp_list">
          <span>地址：南京市玄武区长江路188号27层</span>
        </div>
        <div class="corp_list">
          <img src="../assets/police.png" />
          <span>苏ICP备15058152号-6</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterRoot",
  props: {}
};
</script>

<style lang="less" scoped>
.footer {
  background: linear-gradient(rgb(38, 38, 42) 0%, rgb(13, 12, 14) 100%);
  .footer_container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 44px;
    font-size: 14px;
    letter-spacing: 0.68px;
    .list {
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
      display: flex;
      justify-content: space-between;
      padding-bottom: 28px;
      min-height: 160px;
      .product {
        display: flex;
        .item {
          margin-right: 64px;
          .title {
            color: #ffffff;
            margin-bottom: 16px;
          }
          a {
            color: rgba(255, 255, 255, 0.5);
            text-decoration: none;
            line-height: 20px;
            margin-bottom: 8px;
            display: inline-block;
            &:hover {
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
      .code {
        display: flex;
        align-items: center;
        .qr_box {
          margin-right: 56px;
          img {
            width: 100px;
            height: 100px;
          }
          p {
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            margin-top: 6px;
            line-height: 16px;
          }
        }
        .serve_info {
          p {
            font-size: 14px;
            color: #ffffff;
            margin-top: 12px;
            line-height: 16px;
            &.phone {
              font-size: 28px;
              line-height: 32px;
            }
            &.tip {
              font-size: 13px;
              line-height: 14px;
              margin-bottom: 12px;
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }
    .corp {
      padding-top: 16px;
      padding-bottom: 20px;
      .corp_list {
        padding-bottom: 8px;
        color: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        .divider {
          margin: 0 12px;
        }
        img {
          margin-right: 6px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .footer_container {
    padding: 0 20px;
    .list {
      .product {
        .item {
          margin-right: 24px !important;
        }
      }
      .code {
        .qr_box {
          margin-right: 24px !important;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .footer_container {
    .list {
      border-bottom: 0;
      min-height: auto !important;
      padding-bottom: 40px !important;
      .product {
        flex-wrap: wrap !important;
        align-items: center;
        .item {
          margin-right: 0 !important;
          display: flex;
          flex-wrap: wrap;
          justify-content: left;
          margin-right: 8px !important;
          margin-bottom: 8px;
          .title {
            margin-right: 8px;
            margin-bottom: 0 !important;
          }
          li {
            float: left;

            a {
              margin-right: 8px;
              margin-bottom: 0 !important;
            }
          }
        }
      }
      .code {
        flex-direction: column;
        .qr_box {
          margin-right: 0 !important;
          img {
            width: 80px;
            height: 80px;
          }
        }
        .serve_info {
          text-align: center;
          p {
            &:first-child {
              display: none;
            }
            &.phone {
              font-size: 24px !important;
              white-space: nowrap;
            }
            &.tip {
              margin: 0px !important;
            }
          }
        }
      }
    }
    .corp {
      .corp_list {
        flex-wrap: wrap !important;
      }
    }
  }
}
@media (max-width: 480px) {
  .footer_container {
    .list {
      .product {
        display: none !important;
      }
      .code {
        flex-grow: 1;
        justify-content: space-between;
        .qr_box {
          margin-right: 16px;
          img {
            width: 64px;
            height: 64px;
          }
          p {
            font-size: 12px;
            line-height: 14px;
          }
        }
        .serve_info {
          p {
            &.phone {
              font-size: 24px !important;
            }
            &.tip {
              line-height: 16px !important;
            }
          }
        }
      }
    }
    .corp {
      display: none !important;
    }
  }
}
</style>

<template>
  <div class="mallSearchBox">
    <div class="mallSearchTitle">7600&nbsp;&nbsp;创意营销&nbsp;&nbsp;有我就行</div>
    <div class="searchInputWrapper">
      <div class="mallSearchInput">
        <input
          type="text"
          :placeholder="safePlaceholder"
          allow-clear
          @focus="focusInput"
          @click="focusInput"
          @blur="blurInput"
          @search="onSearch"
          @keyup.enter="onSearch"
          v-model="inputTemplateTitle"
        />
        <span 
          class="searchButton"
          @click="onSearch"
          @keyup.enter="onSearch"
          >搜索
        </span>
      </div>
      <div class="searchDropdown" v-show="isDropdownVisible" ref="searchDropdowm">
        <div class="cardArea" v-if="searchHistory.length && !this.inputTemplateTitle">
          <div class="title">
            最近搜索
            <span @mousedown="clearSearchHistory">清空</span>
          </div>
          <div class="historyWrap">
            <div
              class="historyItem"
              :key="index"
              v-for="(text, index) in searchHistory"
              @mousedown="handleSearchHistory(text)"
            >{{ text }}</div>
          </div>
        </div>
        <div class="cardArea" v-if="mallHotSearch && mallHotSearch.length">
          <div class="title" v-if="!this.inputTemplateTitle">热搜</div>
          <div class="tagWrap">
            <div
              v-for="d in mallHotSearch"
              :key="d.tagId"
              class="tagItem"
              @mousedown="handleSearchHistory(d.tagName)"
            >{{ d.tagName }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mallSearchRecommend" v-if="mallSearchRecommends && mallSearchRecommends.length">
      <span
        v-for="(item, index) in mallSearchRecommends"
        :key="index"
        @mousedown="clickSearchRecommend(item.tagName)"
      >
        {{ item.tagName }}
      </span>
    </div>
  </div>
</template>
<script>
const ActivityTagHotSceneEnum = {
  SKIN_HOT_SEARCH: { id: 1, name: '热搜' },
  SKIN_SEARCH_RECOMMEND: { id: 2, name: '搜索推荐' }
}

import * as activityApi from '@/api/activity' // 1.引入接口
import storage from 'store'
import store from '@/store'
import { getMmsUrl } from "@/utils/util";
export default {
  name: "MallSearchBox",
  data() {
    return {
      ActivityTagHotSceneEnum: ActivityTagHotSceneEnum,
      inputTemplateTitle: '',
      searchHistory: [],
      isDropdownVisible: false,
      isMouseOverDropdown: false,
      isClickSearchRecommend: false
      // inputTemplateTitle: "",
      // searchHistory: ["大转盘", "九宫格", "国庆", "抽奖"],
      // tagSetList: [
      //   { name: "大转盘" },
      //   { name: "九宫格" },
      //   { name: "水果机" },
      //   { name: "砸金蛋" },
      //   { name: "刮刮乐" },
      //   { name: "开盲盒" }
      // ],
      // isDropdownVisible: false,
      // isMouseOverDropdown: false,
      // mallSearchRecommend: [
      //   "抽奖",
      //   "国庆节",
      //   "红包抽奖",
      //   "裂变推广",
      //   "签到积分"
      // ]
    };
  },
  methods: {
    getMmsSkinMallUrl() {
      return getMmsUrl("/skin/mall");
    },
    jumpMmsSkinMall() {
      window.location.href = this.getMmsSkinMallUrl();
    },
    clickSearchRecommend(tagName) {
      this.isClickSearchRecommend = true
      this.handleSearchHistory(tagName)
      // setTimeout(() => {
      //   this.isClickSearchRecommend = false
      // }, 100)
      this.jumpMmsSkinMall()
    },
    querySkinTagHot() {
      activityApi
      .mmsActivityTagHotList({
        scenes: [
          this.ActivityTagHotSceneEnum.SKIN_HOT_SEARCH.id,
          this.ActivityTagHotSceneEnum.SKIN_SEARCH_RECOMMEND.id
        ]
      })
      .then(res => {
        if (res.code === 0 && res.data) {
          store.commit('SET_HOT_TAGS', res.data)
        } else {
          this.$message.error(res.msg || '系统异常')
        }
      })
      .catch(error => {
        this.$message.error(error.response.data.msg || '系统异常')
      })
    },
    focusInput() {
      this.isDropdownVisible = true
    },
    blurInput() {
      setTimeout(() => {
        if (!this.isMouseOverDropdown) {
          this.isDropdownVisible = false
        }
      }, 200)
    },
    onSearch() {
      this.isDropdownVisible = false
      this.disposeSearchHistory()
      this.emitSearchKeywordEvent()
    },
    clearSearchHistory() {
      this.searchHistory = []
      storage.remove('SEARCH_HISTORY')
      this.inputTemplateTitle = ''
    },
    emitSearchKeywordEvent() {
      const keyword = this.inputTemplateTitle
      console.log(keyword)
      // 直接跳往模板中心
      this.jumpMmsSkinMall()
    },
    handleSearchHistory(text) {
      this.inputTemplateTitle = text
      this.disposeSearchHistory()
      this.emitSearchKeywordEvent()
    },
    disposeSearchHistory() {
      if (!this.inputTemplateTitle) {
        this.inputTemplateTitle = ''
      } else {
        const searchHistory = storage.get('SEARCH_HISTORY')
        if (searchHistory && searchHistory.length > 0) {
          this.searchHistory = searchHistory
        }
        for (let i = 0; i < this.searchHistory.length; i++) {
          if (this.searchHistory[i] === this.inputTemplateTitle) {
            this.searchHistory.splice(i, 1)
          }
        }
        this.searchHistory.unshift(this.inputTemplateTitle)
        if (this.searchHistory.length > 6) {
          this.searchHistory.pop()
        }
        storage.set('SEARCH_HISTORY', this.searchHistory)
      }
    },
    handleMouseOverDropdown() {
      this.isMouseOverDropdown = true
      this.focusInput()
    },
    handleMouseOutDropdown() {
      this.isMouseOverDropdown = false
      this.blurInput()
    }
  },
  computed: {
    hotTags() {
      return this.$store.getters.hotTags
    },
    mallSearchRecommends() {
      if (!this.hotTags) {
        return null
      }
      const list = this.hotTags.filter(e => e.scene == this.ActivityTagHotSceneEnum.SKIN_SEARCH_RECOMMEND.id)
      if (!list) {
        return null
      }
      list.sort((a, b) => { a.sortNum - b.sortNum })
      // console.log(list)
      return list.slice(0, 5)
    },
    mallHotSearch() {
      if (!this.hotTags) {
        return null
      }
      const list = this.hotTags.filter(e => e.scene == this.ActivityTagHotSceneEnum.SKIN_HOT_SEARCH.id)
      if (!list) {
        return null
      }
      list.sort((a, b) => { a.sortNum - b.sortNum })
      // console.log(list)
      return list
    },
    safePlaceholder() {
      if (!this.mallSearchRecommends || this.mallSearchRecommends.length == 0) {
        return '搜索模板'
      } else {
        return this.mallSearchRecommends[0].shortName
      }
    }
  },
  created() {
    this.querySkinTagHot()

    const searchHistory = storage.get('SEARCH_HISTORY')
    if (searchHistory && searchHistory.length > 0) {
      this.searchHistory = searchHistory
    }
  },
  mounted() {
  },
  beforeDestroy() {
  }
};
</script>
<style lang="less" scoped>
.mallSearchBox {
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .mallSearchTitle {
    margin-top: 48px;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    color: #ffffff;
  }
  .searchInputWrapper {
    position: relative;
    .mallSearchInput {
      z-index: 444;
      width: 560px;
      height: 48px;
      background: #ffffff;
      border-radius: 8px;
      padding: 4px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      input[type="text"] {
        height: 40px;
        flex: 1;
        border: 0;
        padding: 0 16px 0 12px;
        outline: none;
        font-size: 16px;
      }
      .searchButton {
        width: 96px;
        line-height: 40px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        background-color: #ff5448;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .searchDropdown {
      position: absolute;
      top: 52px;
      left: 0;
      z-index: 1000;
      background: #ffffff;
      box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.06),
        0 0 2px 0 rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 16px;
      width: 560px;

      .cardArea {
        padding-top: 12px;
        border-top: 1px solid rgba(30, 32, 35, 0.06);
        padding-bottom: 12px;
        &:first-child {
          padding-top: 0;
          border-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
        .title {
          display: flex;
          justify-content: space-between;
          color: #999999;
          font-size: 14px;
          margin-bottom: 8px;
          font-weight: bold;
          span {
            cursor: pointer;
            color: #666666;
            font-weight: normal;
            &:hover {
              color: #3388ff;
            }
          }
        }
        .historyWrap {
          display: flex;
          flex-wrap: wrap;
          .historyItem {
            max-width: 100px;
            border-radius: 4px;
            padding: 5px 8px;
            text-align: center;
            // color: #4d4f52;
            color: #333333;
            background: #f7f9fa;
            cursor: pointer;
            line-height: 20px;
            white-space: nowrap;
            margin: 0 6px 6px 0;
            font-size: 14px;
            &:hover {
              background: #f5f5f5;
            }
          }
        }
        .tagWrap {
          max-height: 300px;
          overflow-y: auto;
          margin-left: -12px;
          margin-right: -12px;
          .tagItem {
            font-size: 14px;
            height: 36px;
            line-height: 36px;
            padding-left: 16px;
            color: #333333;
            cursor: pointer;
            &:hover {
              background: #f5f5f5;
            }
          }
        }
      }
    }
  }

  .mallSearchRecommend {
    color: #ffffff;
    margin-bottom: 48px;
    font-size: 14px;
    span {
      margin-right: 16px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
<template>
  <div>
    <HeaderRoot></HeaderRoot>
    <div class="part">
      <MallSearchBox></MallSearchBox>
      <div class="partTab">
        <div
          class="tab"
          v-for="(tab,index) in tabs"
          :key="index"
          :class="{active:index === activeTab}"
          @click="selectTab(index)"
          @mouseover="hoverTab(index)"
          @mouseleave="leaveTab"
        >
          <div class="tabImg">
            <img :src="tab.imgSrc" />
          </div>
          <p class="tabName">{{ tab.name }}</p>
          <span class="tabNarrow" v-show="index === activeTab"></span>
        </div>
      </div>
    </div>

    <div class="pagePart">
      <div class="main">
        <div class="partTitle">
          <div class="text">同行都在用的{{ currentTab.name }}模版</div>
          <a :href="getMmsSkinMallUrl()" class="linkMore">查看更多></a>
        </div>
        <div class="partContent">
          <div class="goodsSlider" v-if="activeTab > -1">
            <a class="createWork" :href="getMmsSkinMallUrl()">
              <div class="icon">
                <img src="../assets//create_work_icon.png" />
              </div>
              <span>创建作品</span>
            </a>
            <div
              class="slider"
              ref="slider"
              :style="{ width: sliderWidth + 'px', overflow: 'hidden' }"
            >
              <div
                class="goodsList"
                :style="{display: 'flex', transition: 'transform 0.3s ease',transform: `translateX(-${currentTranslate}px)`}"
              >
                <div
                  class="goodsItem"
                  v-for="(item, index) in tabs[activeTab].items"
                  :key="index"
                  :style="{ flex: '0 0 auto', width: itemWidth + 'px' }"
                  @mouseover="hoverSkinImage()"
                  @mouseleave="leaveSkinImage()"
                  @click="jumpMmsSkinMall()"
                >
                  <a :href="getMmsSkinMallUrl()" class="image_box">
                    <div class="tag tag_view" @click="jumpMmsSkinPreviewUrl(item.skinId)">
                      <img src="../assets/pre_view.png" />
                    </div>
                    <div class="tag tag_member" v-show="item.expense=='会员免费用'">会员免费用</div>
                    <div class="tag tag_free" v-show="item.expense=='免费'">免费</div>
                    <div class="tag type_name">{{item.tag}}</div>
                    <img :src="item.imageUrl" />
                  </a>
                  <div class="card_info">
                    <div class="info_title">{{item.title}}</div>
                    <div class="info_num">
                      <div class="type_name_tip">{{item.tag}}</div>
                      <div class="num_eye">
                        <img src="../assets/icon_eye.png" />
                        <span>{{item.num}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--轮播图导航按钮-->
            <div
              class="swiper_button_prev"
              @click="toLeft"
              v-show="canSlideLeft"
              @mouseover="hoverSkinImage()"
              @mouseleave="leaveSkinImage()"
            >
              <img class="arrow" src="../assets/arrow_left.png" />
              <img class="arrowDark" src="../assets/arrow_left_dark.png" />
            </div>
            <div
              class="swiper_button_next"
              @click="toRight"
              v-show="canSlideRight"
              @mouseover="hoverSkinImage()"
              @mouseleave="leaveSkinImage()"
            >
              <img class="arrow" src="../assets/arrow_right.png" />
              <img class="arrowDark" src="../assets/arrow_right_dark.png" />
            </div>
          </div>
          <div v-else class="noContent">
            <img src="../assets/noContent.png" />
            <p>暂无内容</p>
          </div>
        </div>
      </div>
    </div>
    <FooterRoot></FooterRoot>
  </div>
</template>

<script>
import HeaderRoot from "@/components/HeaderRoot.vue";
import MallSearchBox from "@/components/MallSearchBox.vue";
import FooterRoot from "@/components/FooterRoot.vue";
import { getMmsUrl } from "@/utils/util";

export default {
  name: "WebHome",
  props: {},
  components: {
    HeaderRoot,
    MallSearchBox,
    FooterRoot
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          name: "大转盘",
          imgSrc: require("../assets/templates/dazhuanpan5-main.jpg"),
          items: [
            {
              skinId: 1,
              imageUrl: require("../assets/templates/dazhuanpan1-main.jpg"),
              title: "红色喜庆幸运大抽奖大转盘互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "476"
            },
            {
              skinId: 2,
              imageUrl: require("../assets/templates/dazhuanpan2-main.jpg"),
              title: "清新薄荷夏日微风幸运大转盘互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "246"
            },
            {
              skinId: 3,
              imageUrl: require("../assets/templates/dazhuanpan3-main.jpg"),
              title: "紫色梦幻幸运转转转不停大转盘互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "57"
            },
            {
              skinId: 4,
              imageUrl: require("../assets/templates/dazhuanpan4-main.jpg"),
              title: "活力橙红狂欢幸运大抽奖大转盘互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "107"
            },
            {
              skinId: 5,
              imageUrl: require("../assets/templates/dazhuanpan5-main.jpg"),
              title: "清凉夏日感幸运大抽奖大转盘互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "144"
            },
            {
              skinId: 6,
              imageUrl: require("../assets/templates/dazhuanpan6-main.jpg"),
              title: "红色热烈气氛幸运大转盘互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "195"
            },
            {
              skinId: 7,
              imageUrl: require("../assets/templates/dazhuanpan7-main.jpg"),
              title: "迎新年送福利新年红色喜庆氛围大转盘互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "478"
            },
            {
              skinId: 8,
              imageUrl: require("../assets/templates/dazhuanpan8-main.jpg"),
              title: "迎国庆抽好礼庆祝国庆节大转盘互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "260"
            },
            {
              skinId: 9,
              imageUrl: require("../assets/templates/dazhuanpan9-main.jpg"),
              title: "庆元旦迎新年沉稳棕色元旦节大转盘互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "591"
            }
          ]
        },
        {
          name: "九宫格",
          imgSrc: require("../assets/templates/jiugongge2-main.jpg"),
          items: [
            {
              skinId: 2001,
              imageUrl: require("../assets/templates/jiugongge1-main.jpg"),
              title: "碧海蓝天领积分抽大奖九宫格互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "144"
            },
            {
              skinId: 2002,
              imageUrl: require("../assets/templates/jiugongge2-main.jpg"),
              title: "夕阳橙黄温暖欢乐九宫格幸运抽大奖互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "1657"
            },
            {
              skinId: 2003,
              imageUrl: require("../assets/templates/jiugongge3-main.jpg"),
              title: "浅蓝清新脱俗清新空气九宫格幸运抽大奖互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "76"
            },
            {
              skinId: 2004,
              imageUrl: require("../assets/templates/jiugongge4-main.jpg"),
              title: "清新绿野九宫格互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "24"
            },
            {
              skinId: 2005,
              imageUrl: require("../assets/templates/jiugongge5-main.jpg"),
              title: "鲜艳橙红欢快九宫格幸运抽大奖互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "899"
            },
            {
              skinId: 2006,
              imageUrl: require("../assets/templates/jiugongge6-main.jpg"),
              title: "宁静致远九宫格积分抽大奖互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "297"
            },
            {
              skinId: 2007,
              imageUrl: require("../assets/templates/jiugongge7-main.jpg"),
              title: "红色热力活力九宫格幸运大抽奖互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "476"
            }
          ]
        },
        {
          name: "刮刮乐",
          imgSrc: require("../assets/templates/guaguale7-main.jpg"),
          items: [
            {
              skinId: 1001,
              imageUrl: require("../assets/templates/guaguale1-main.jpg"),
              title: "梦幻幸运刮刮乐互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "76"
            },
            {
              skinId: 1002,
              imageUrl: require("../assets/templates/guaguale2-main.jpg"),
              title: "红色热情幸运刮大奖刮刮乐互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "24"
            },
            {
              skinId: 1003,
              imageUrl: require("../assets/templates/guaguale3-main.jpg"),
              title: "炽热红火红色幸运刮刮乐互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "899"
            },
            {
              skinId: 1004,
              imageUrl: require("../assets/templates/guaguale4-main.jpg"),
              title: "浪漫粉霞幸运刮刮乐互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "297"
            },
            {
              skinId: 1005,
              imageUrl: require("../assets/templates/guaguale5-main.jpg"),
              title: "活力橙光幸运刮刮乐互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "476"
            },
            {
              skinId: 1006,
              imageUrl: require("../assets/templates/guaguale6-main.jpg"),
              title: "手绘刮趣缤纷糖果彩色幸运刮刮乐互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "246"
            },
            {
              skinId: 1007,
              imageUrl: require("../assets/templates/guaguale7-main.jpg"),
              title: "梦幻紫色星空幻想幸运刮刮乐互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "57"
            },
            {
              skinId: 1008,
              imageUrl: require("../assets/templates/guaguale8-main.jpg"),
              title: "宁静蔚蓝幸运刮刮乐互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "107"
            }
          ]
        },
        {
          name: "盲盒",
          imgSrc: require("../assets/templates/manghechoujiang9-main.jpg"),
          items: [
            {
              skinId: 3001,
              imageUrl: require("../assets/templates/manghechoujiang1-main.jpg"),
              title: "绿色端午开好运抽盲盒抽奖互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "76"
            },
            {
              skinId: 3002,
              imageUrl: require("../assets/templates/manghechoujiang2-main.jpg"),
              title: "科技深紫色调精选抽盲盒抽奖互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "24"
            },
            {
              skinId: 3003,
              imageUrl: require("../assets/templates/manghechoujiang3-main.jpg"),
              title: "梦幻粉霞幸运开盲盒抽奖互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "899"
            },
            {
              skinId: 3004,
              imageUrl: require("../assets/templates/manghechoujiang4-main.jpg"),
              title: "紫色惊喜盲盒抽奖互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "297"
            },
            {
              skinId: 3005,
              imageUrl: require("../assets/templates/manghechoujiang5-main.jpg"),
              title: "清新田园风盲盒开好运抽奖互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "476"
            },
            {
              skinId: 3006,
              imageUrl: require("../assets/templates/manghechoujiang6-main.jpg"),
              title: "金色抽盲盒开惊喜抽奖互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "246"
            },
            {
              skinId: 3007,
              imageUrl: require("../assets/templates/manghechoujiang7-main.jpg"),
              title: "浪漫玫红幸运抽盲盒抽奖互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "57"
            },
            {
              skinId: 3009,
              imageUrl: require("../assets/templates/manghechoujiang8-main.jpg"),
              title: "红色热情盲盒抽大奖互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "107"
            }
          ]
        },
        {
          name: "水果机",
          imgSrc: require("../assets/templates/shuiguoji2-main.jpg"),
          items: [
            {
              skinId: 4001,
              imageUrl: require("../assets/templates/shuiguoji1-main.jpg"),
              title: "樱花粉黛抽奖乐翻天水果机互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "476"
            },
            {
              skinId: 4002,
              imageUrl: require("../assets/templates/shuiguoji2-main.jpg"),
              title: "蓝天草地幸运抽奖活动水果机互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "246"
            },
            {
              skinId: 4003,
              imageUrl: require("../assets/templates/shuiguoji3-main.jpg"),
              title: "清新浪漫狂欢抽大奖水果机互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "57"
            },
            {
              skinId: 4004,
              imageUrl: require("../assets/templates/shuiguoji4-main.jpg"),
              title: "狂欢抽大奖水果机互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "107"
            },
            {
              skinId: 4005,
              imageUrl: require("../assets/templates/shuiguoji5-main.jpg"),
              title: "暗夜魅影狂欢抽大奖水果机互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "144"
            },
            {
              skinId: 4006,
              imageUrl: require("../assets/templates/shuiguoji6-main.jpg"),
              title: "红色欢快狂欢抽大奖水果机互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "1657"
            },
            {
              skinId: 4007,
              imageUrl: require("../assets/templates/shuiguoji7-main.jpg"),
              title: "神秘天空狂欢抽大奖水果机互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "144"
            },
            {
              skinId: 4008,
              imageUrl: require("../assets/templates/shuiguoji8-main.jpg"),
              title: "甜蜜梦境幸运抽奖活动水果机互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "1657"
            }
          ]
        },
        {
          name: "砸金蛋",
          imgSrc: require("../assets/templates/zajindan6-main.jpg"),
          items: [
            {
              skinId: 5001,
              imageUrl: require("../assets/templates/zajindan1-main.jpg"),
              title: "红色喜庆热闹疯狂砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "476"
            },
            {
              skinId: 5002,
              imageUrl: require("../assets/templates/zajindan2-main.jpg"),
              title: "生机绿色草地欢乐砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "246"
            },
            {
              skinId: 5003,
              imageUrl: require("../assets/templates/zajindan3-main.jpg"),
              title: "紫色梦幻舞台欢乐砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "57"
            },
            {
              skinId: 5004,
              imageUrl: require("../assets/templates/zajindan4-main.jpg"),
              title: "紫色梦幻舞台欢乐砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "免费",
              num: "107"
            },
            {
              skinId: 5005,
              imageUrl: require("../assets/templates/zajindan5-main.jpg"),
              title: "红色复古舞台砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "144"
            },
            {
              skinId: 5006,
              imageUrl: require("../assets/templates/zajindan6-main.jpg"),
              title: "宁静平和蓝色欢乐砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "1657"
            },
            {
              skinId: 5007,
              imageUrl: require("../assets/templates/zajindan7-main.jpg"),
              title: "童话王国砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "144"
            },
            {
              skinId: 5008,
              imageUrl: require("../assets/templates/zajindan8-main.jpg"),
              title: "清新草地砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "1657"
            },
            {
              skinId: 5009,
              imageUrl: require("../assets/templates/zajindan9-main.jpg"),
              title: "复古水墨风欢乐砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "144"
            },
            {
              skinId: 5010,
              imageUrl: require("../assets/templates/zajindan10-main.jpg"),
              title: "红色卡通欢乐砸金蛋互动小游戏",
              tag: "活动抽奖",
              expense: "会员免费用",
              num: "1657"
            }
          ]
        }
      ],
      autoSwitchInterval: 2000, //自动切换间隔时间，单位为毫秒
      itemIntervalID: null, //定时器ID
      currentTranslate: 0, // 当前滑动的位移
      itemWidth: 180, // 每个goodsItem的宽度
      sliderWidth: 996, // slider的宽度
      mmsurl: process.env.VUE_APP_MMS_BASE_URL
    };
  },
  methods: {
    getMmsSkinMallUrl() {
      return getMmsUrl("/skin/mall");
    },
    jumpMmsSkinMall() {
      window.location.href = this.getMmsSkinMallUrl();
    },
    getMmsSkinPreviewUrl(skinId) {
      return getMmsUrl("/skin/preview/?skinId=" + skinId);
    },
    jumpMmsSkinPreviewUrl(skinId) {
      window.open(this.getMmsSkinPreviewUrl(skinId), "_blank");
    },
    hoverSkinImage() {
      this.hoverTab(this.activeTab);
    },
    leaveSkinImage() {
      this.leaveTab();
    },

    handleMouseOverDropdown() {
      this.isMouseOverDropdown = true;
    },
    handleMouseOutDropdown() {
      this.isMouseOverDropdown = false;
    },
    hoverTab(index) {
      this.activeTab = index;
      this.stopAutoSwitch(); // 鼠标悬浮时停止自动切换
    },
    leaveTab() {
      this.startAutoSwitch(); // 鼠标离开时重新启动自动切换
    },
    startAutoSwitch() {
      this.itemIntervalID = setInterval(() => {
        this.activeTab = (this.activeTab + 1) % this.tabs.length;
      }, this.autoSwitchInterval);
    },
    stopAutoSwitch() {
      if (this.itemIntervalID !== null) {
        clearInterval(this.itemIntervalID);
        this.itemIntervalID = null;
      }
    },
    selectTab(index) {
      this.activeTab = index;
    },
    resetSliderPosition() {
      this.currentTranslate = 0; // 重置slider位置到最左侧
    },
    // updateCurrentTabItemsIndex(index) {
    //   this.currentTabItemsIndex = index;
    //   // this.currentTabItems = this.tabs[index].items;
    // },

    //伸缩
    setActive(index) {
      this.activeIndex = index; //更新activeIndex来动态改变active类
    },
    resetSlidePosition() {
      this.currentTranslate = 0;
    },
    toLeft() {
      if (this.canSlideLeft) {
        // 根据需求调整滑动的步长，这里假设每次滑动一个商品项的宽度+ 间距
        const step = this.itemWidth + 24;
        this.currentTranslate -= step;
        if (this.currentTranslate < 0) {
          this.currentTranslate = 0; // 防止滑过界
        }
      }
    },
    toRight() {
      if (this.canSlideRight) {
        // 根据需求调整滑动的步长，这里假设每次滑动一个商品项的宽度+间距
        const step = this.itemWidth + 24;
        this.currentTranslate += step;
        // 计算最大滑动位移，防止滑过界
        const maxTranslate = this.totalItemsWidth - this.sliderWidth;
        if (this.currentTranslate > maxTranslate) {
          this.currentTranslate = maxTranslate;
        }
      }
    }
  },
  computed: {
    currentTab() {
      // console.log(this.activeTab);
      // console.log(this.tabs[this.activeTab]);
      return this.tabs[this.activeTab];
    },
    // currentTabItems() {
    //   // 根据当前选中的 tab 名称从 allItems 中获取对应的 items
    //   console.log(this.currentTab.items);
    //   return this.currentTab.items;
    // },
    totalItemsWidth() {
      return (
        this.tabs[this.activeTab].items.length * (this.itemWidth + 24) - 24
      ); // 减去最后一个元素的右边距
      // return this.currentTabItems.length * (this.itemWidth + 24) - 24; // 减去最后一个元素的右边距
    },
    canSlideLeft() {
      return this.currentTranslate > 0;
    },
    canSlideRight() {
      return this.currentTranslate + this.sliderWidth < this.totalItemsWidth;
    }
  },
  mounted() {
    //轮播图自动播放
    this.startAutoSwitch();
    //搜索
    const dropdown = this.$refs.searchDropdown;
    if (dropdown) {
      dropdown.addEventListener("mouseover", this.handleMouseOverDropdown);
      dropdown.addEventListener("mouseout", this.handleMouseOutDropdown);
    }
  },
  beforeDestroy() {
    this.stopAutoSwitch();
    const dropdown = this.$refs.searchDropdown;
    if (dropdown) {
      dropdown.removeEventListener("mouseover", this.handleMouseOverDropdown);
      dropdown.removeEventListener("mouseout", this.handleMouseOutDropdown);
    }
  },
  watch: {
    activeTab(newIndex) {
      console.log(newIndex);
      // 当activeTab变化时，重置slider位置
      this.resetSliderPosition();
      // 更新当前tab对应的items
      // this.updateCurrentTabItemsIndex(newIndex);
    }
  }
};
</script>

<style lang="less" scoped>
//分类
.part {
  position: relative;
  background-color: #336699;
  // background-image: url("../assets/searchBg.gif");
  background-image: url("../assets/search-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 44px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 30, 70, 0.7);
    z-index: 1;
  }

  .partTab {
    z-index: 2;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .tab {
      position: relative;
      width: calc((1200px - 120px) / 6);
      height: 152px;
      padding: 12px 12px 0;
      border-radius: 18px;
      background: #3388ff;
      color: #ffffff;
      &:hover {
        cursor: pointer;
      }
      &.active {
        background: #ffffff;
        color: #333333;
      }
      .tabImg {
        border-radius: 14px;
        background: #e1e9f9;
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;

        img {
          border-radius: 4px;
          max-width: 100%;
          max-height: 100%;
          width: auto;
        }
      }
      p {
        padding-top: 10px;
        line-height: 22px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }
      .tabNarrow {
        position: absolute;
        // left: 48px;
        left: calc(50% - 29px);
        bottom: -44px;
        width: 59px;
        height: 21px;
        background: url("../assets/hd-tab-narow.png") no-repeat 50%;
        background-size: contain;
      }
    }
  }
}
//分类内容
.pagePart {
  background: #f5f8ff;
  .main {
    width: 1200px;
    padding-top: 40px;
    margin: 0 auto;
    .partTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
      }
      .linkMore {
        color: #666666;
        text-decoration: none;
        &:hover {
          color: #3388ff;
        }
      }
    }
    .partContent {
      .goodsSlider {
        position: relative;
        display: flex;
        .createWork {
          flex-shrink: 0;
          border: 1px solid #e6ebed;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 180px;
          height: 279px;
          background: rgba(#ffffff, 0.4);
          margin: 32px 24px 80px 0;
          &:hover {
            background: #ffffff;
            transform: translate3d(0, -2px, 0);
            border-color: #fff;
            box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
              0 6px 16px 0 rgba(0, 0, 0, 0.08),
              0 3px 6px -4px rgba(0, 0, 0, 0.12);
          }
          .icon {
            background: #3388ff;
            border-radius: 50%;
            margin-bottom: 12px;
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 24px;
            }
          }
        }
        .slider {
          flex-grow: 1;
          flex-shrink: 0;
          // position: relative;
          padding: 32px 0 80px;
          .goodsList {
            display: flex;
            .goodsItem {
              flex-shrink: 0;
              margin-right: 24px !important;
              width: 180px;
              height: 279px;
              border-radius: 6px;
              overflow: hidden;
              &:hover {
                top: -13px;
                box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
                  0 6px 16px 0 rgba(0, 0, 0, 0.08),
                  0 3px 6px -4px rgba(0, 0, 0, 0.12);
                overflow: visible;
                z-index: 2;
                .image_box {
                  border-radius: 6px 6px 0 0;
                  top: -13px;
                  img {
                    border-radius: 6px 6px 0 0;
                  }
                }
                .type_name {
                  opacity: 0 !important;
                }
                .tag_member,
                .tag_free,
                .card_info,
                .tag_view {
                  opacity: 1 !important;
                }
                .card_info {
                  bottom: -47px;
                  opacity: 1 !important;
                  box-shadow: 0 6px 14px 0 rgb(0 0 0 / 8%),
                    0 3px 6px -4px rgb(0 0 0 / 12%);
                }
              }
              .image_box {
                z-index: 1;
                position: absolute;
                border-radius: 4px;
                cursor: pointer;
                height: 279px;
                overflow: hidden;
                img {
                  width: 180px;
                }
                .tag {
                  position: absolute;
                  z-index: 2;
                  height: 20px;
                  min-width: 36px;
                  line-height: 20px;
                  padding: 0 4px;
                  border-radius: 4px;
                  text-align: center;
                  font-size: 12px;
                  // font-weight: 600;
                  &.tag_view {
                    cursor: pointer;
                    opacity: 0;
                    top: 8px;
                    right: 8px;
                    height: 24px;
                    width: 24px;
                    min-width: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(0, 0, 0, 0.6);
                    img {
                      width: 16px;
                    }
                    &:hover {
                      background-color: rgba(0, 0, 0, 0.7);
                    }
                  }
                  &.tag_member {
                    opacity: 0;
                    bottom: 8px;
                    right: 8px;
                    background: linear-gradient(138deg, #ffeed4, #ffd59f);
                    color: #6c300a;
                  }
                  &.tag_free {
                    opacity: 0;
                    right: 8px;
                    bottom: 4px;
                    background: linear-gradient(354deg, #3b3b52, #75758b);
                    color: #ffead8;
                  }
                  &.type_name {
                    opacity: 1;
                    left: 8px;
                    bottom: 8px;
                    background: rgba(0, 0, 0, 0.6);
                    color: #ffffff;
                  }
                }
              }
              .card_info {
                position: absolute;
                bottom: -60px;
                width: 180px;
                height: 60px;
                background: #ffffff;
                border-radius: 0 0 6px 6px;
                padding: 0 8px;
                opacity: 0;
                .info_title {
                  font-size: 14px;
                  line-height: 20px;
                  margin-top: 8px;
                  margin-bottom: 4px;
                  color: #1a1a1a;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
                .info_num {
                  font-size: 12px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  color: #595959;
                  line-height: 20px;
                  .num_eye {
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }
          }
        }
        .swiper_button_prev,
        .swiper_button_next {
          position: absolute;
          z-index: 10;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
            0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
          background: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 154px;

          .arrowDark {
            display: block;
          }
          .arrow {
            display: none;
          }
          &:hover {
            background-color: #3388ff;
            .arrowDark {
              display: none;
            }
            .arrow {
              display: block;
            }
          }
        }
        .swiper_button_prev {
          left: 186px;
          right: auto;
        }
        .swiper_button_next {
          left: auto;
          right: -18px;
        }
      }

      .noContent {
        height: calc(32px + 279px + 80px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          margin-top: 24px;
        }
      }
    }
  }
}
</style>

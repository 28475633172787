<template>
  <div class="mobileHomeWrapper">
    <MobileHeaderRoot></MobileHeaderRoot>
    <MobileSkinStore></MobileSkinStore>
    <MobileFooterRoot></MobileFooterRoot>
  </div>
</template>
<script>
import MobileFooterRoot from "@/components/MobileFooterRoot.vue";
import MobileHeaderRoot from "@/components/MobileHeaderRoot .vue";
import MobileSkinStore from "@/components/MobileSkinStore.vue";

export default {
  name: "MobileHome",
  components: { MobileHeaderRoot, MobileFooterRoot, MobileSkinStore },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.mobileHomeWrapper {
  background-color: #ffffff;
}
</style>

const activity = {
  state: {
    allSkinTags: null,
    hotTags: null
  },

  mutations: {
    SET_ALL_SKIN_TAGS: (state, allSkinTags) => {
      state.allSkinTags = allSkinTags
    },
    SET_HOT_TAGS: (state, hotTags) => {
      state.hotTags = hotTags
    }
  },

  actions: {}
}

export default activity
